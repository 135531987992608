// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-datenschutz-jsx": () => import("./../../../src/pages/datenschutz.jsx" /* webpackChunkName: "component---src-pages-datenschutz-jsx" */),
  "component---src-pages-en-dataprotection-jsx": () => import("./../../../src/pages/en/dataprotection.jsx" /* webpackChunkName: "component---src-pages-en-dataprotection-jsx" */),
  "component---src-pages-en-terms-jsx": () => import("./../../../src/pages/en/terms.jsx" /* webpackChunkName: "component---src-pages-en-terms-jsx" */),
  "component---src-pages-impressum-jsx": () => import("./../../../src/pages/impressum.jsx" /* webpackChunkName: "component---src-pages-impressum-jsx" */),
  "component---src-templates-top-index-jsx": () => import("./../../../src/templates/top-index.jsx" /* webpackChunkName: "component---src-templates-top-index-jsx" */)
}

